import { forkJoin, Subject } from 'rxjs';
import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { GridSubAction } from '@shared/ag-grid/component/sub-action/sub-action.enum';
import { GridState } from '@shared/ag-grid/gird-state';
import { OfftakersListGridSettingService } from './offtakers-list-grid-setting.service';
import { OfftakerService } from './offtaker.service';
import { Offtaker } from './offtaker.model';
import { TranslateService } from '@ngx-translate/core';
import { ProjectSet } from './project-set/project-set.model';
import { SelectedProjectSetsService } from './offtakers-list-action/selected-project-sets.service';
import { ProjectSetService } from './project-set/project-set.service';
import { ProjectSetPortfolio } from './project-set-portfolio/project-set-portfolio.model';
import { ProjectSetPortfolioService } from './project-set-portfolio/project-set-portfolio.service';
import { EventService } from '@shared/event.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, ViewportScroller } from '@angular/common';

@Component({
  selector: 'oes-offtakers-list',
  templateUrl: 'offtakers-list.component.html',
  styleUrls: ['./offtakers-list.component.scss'],
  providers: []
})
export class OfftakersListComponent implements OnInit, OnDestroy {
  @ViewChild('createModal', {static: false}) createModal: ModalDirective;

  activeTab = 'offtakers';
  columnApi: ColumnApi;
  copying: boolean = false;
  defaultGridState: GridState;
  displayedProjectSets: ProjectSet[];
  gridApi: GridApi;
  gridOptions: GridOptions = {};
  gridState: GridState;
  offtakers: Offtaker[];
  selectedProjectSetIds = [];

  private gridStateStorageKey: string;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _offtakerService: OfftakerService,
              public _offtakersListGridSettingService: OfftakersListGridSettingService,
              private _selectedProjectSetsService: SelectedProjectSetsService,
              private _eventService: EventService,
              private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _viewportScroller: ViewportScroller,
              private _location: Location,
              private _projectSetPortfolioService: ProjectSetPortfolioService,
              private _projectSetService: ProjectSetService,
              private _translateService: TranslateService) {
    this.gridOptions = this._offtakersListGridSettingService.getGridOptions();
  }

  ngOnInit() {
    this.selectTabFromRoute();
    this.gridInit();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private selectTabFromRoute() {
    this._activatedRoute.queryParams
    .pipe(take(1))
    .subscribe(queryParams => {
      this.activeTab = queryParams.tab ? queryParams.tab : 'offtakers';
      this.selectTab(this.activeTab);
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this._offtakersListGridSettingService.setGridApi(params.api, params.columnApi);
    this.defaultGridState = this._offtakersListGridSettingService.buildDefaultGridState();
    this.getOfftakers();
  }

  getOfftakers() {
    this._offtakerService.list()
    .pipe(take(1))
    .subscribe((offtakers: Offtaker[]) => {
      if (!offtakers?.length) {
        this.activeTab = 'introduction';
      }
      this.offtakers = offtakers.map(ot => new Offtaker(ot));
      this.displayedProjectSets = this.offtakers.reduce((accum, offtaker) => {
        offtaker.projectSets.forEach(ps => accum.push(ps));
        return accum;
      }, []);
      this.setAgGrid(this.displayedProjectSets);
    });
  }

  hideCreateModal() {
    this.createModal.hide();
    this.getOfftakers();
  }

  selectTab(tab: string) {
    this.activeTab = tab;
    const urlTree = this._router.createUrlTree([], {
      queryParams: { tab: tab },
      queryParamsHandling: 'merge',
      preserveFragment: true
    });
    this._location.go(urlTree.toString());
  }

  goToPortfolios() {
    this._router.navigateByUrl('/oes/finance/portfolios?tab=c-and-i');
  }

  private setAgGrid(projectSets: ProjectSet[]) {
    if (projectSets?.length > 0) {
      this.gridApi.setRowData(projectSets);
      this._offtakersListGridSettingService.applyStoredGridState(this.gridStateStorageKey, this.defaultGridState);
      this.gridApi.collapseAll();

    } else {
      this.gridApi.showNoRowsOverlay();
    }
  }

  // ag-grid callback: filter, sort and group
  gridStatusChanged(event, type) {
    this.storeGridState();
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._offtakersListGridSettingService.storeGridStateByApis(this.gridStateStorageKey, this.gridApi, this.columnApi);
    }
  }

  prepareDelete(projectSetIds: string[]) {
    const deleteProjectSetRequests = projectSetIds.map(id => {
      return this._projectSetService.remove(id);
    });
    forkJoin(deleteProjectSetRequests)
    .pipe(take(1))
    .subscribe((res) => {
      this.deleteEmptyOfftakers();
    });
  }

  deleteEmptyOfftakers() {
    this._offtakerService.list()
    .pipe(take(1))
    .subscribe((offtakers: Offtaker[]) => {
      const deleteOfftakerRequests = offtakers.filter(ot => {
        if (!ot.projectSets?.length) {
          return true;
        }
        return false;
      }).map((offtakerToDelete: Offtaker) => {
        return this._offtakerService.remove(offtakerToDelete.id);
      });
      if (!deleteOfftakerRequests.length) {
        this.getOfftakers();
      }
      forkJoin(deleteOfftakerRequests)
        .pipe(take(1))
        .subscribe(res => {
          this.getOfftakers();
        });
    });
  }

  subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList: {
        const siteGroupsText = this._translateService.instant('offtaker.site-groups');
        const contractingArrangementTypes = this._translateService.instant('offtaker.contracting-arrangement');
        const stages = this._translateService.instant('offtaker.project-stage');

        const processCellCallback = (params: any) => {
          switch (params.column?.colId) {
            case 'stage':
              return stages[params.value];
            case 'contractType':
              return contractingArrangementTypes[params.value];
            case 'currency':
            case 'totalSites':
            case 'country.name':
            default:
              return params.value;
          }
        };
        this._offtakersListGridSettingService.exportCsv(this.gridApi, 'Customers', true, processCellCallback);
        break;
      }
      case GridSubAction.reload:
        this.getOfftakers();
        break;
      case GridSubAction.clearFilter:
        this._offtakersListGridSettingService.clearStoredGridState(this.gridStateStorageKey);
        break;
    }
  }

  private gridInit() {
    this.gridStateStorageKey = this._offtakersListGridSettingService.buildGridStateStorageKey('myOfftakers');
  }

  selectionChanged(event) {
    const selectedProjectSets = event.api.getSelectedNodes().filter(item => item.data).map(item => item.data);
    this._selectedProjectSetsService.projectSets = selectedProjectSets;
    this.selectedProjectSetIds = selectedProjectSets.map(item => item.id);
  }

  createPortfolio(projectSetPortfolio: ProjectSetPortfolio) {
    this._projectSetPortfolioService.create(projectSetPortfolio)
      .pipe(take(1))
      .subscribe((response: ProjectSetPortfolio) => {
        this._eventService.success(this._translateService.instant('offtaker.list.portfolio-created'));
        this._selectedProjectSetsService.clear();
        this.gridApi.deselectAll();
      });
  }

  scrollToTop() {
    this._viewportScroller.scrollToPosition([0,0]);
  }
}
