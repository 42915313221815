<oes-nav-header></oes-nav-header>

<div>
  <!-- List Begin -->
  <div class="nav-buttons tabs">
    <tabset>
      <tab [active]="activeTab === 'introduction'"
           (selectTab)="selectTab('introduction')"
           heading="{{ 'offtaker.list.introduction' | translate }}"
           index="0">

        <div class="primary-content-container section-break">
          <div class="primary-content-left">
            <p class="preheader">{{ 'offtaker.list.introducing' | translate }}</p>
            <h1>{{ 'offtaker.list.manage-offtakers' | translate }}</h1>
            <h4>{{ 'offtaker.list.we-are-building' | translate }}</h4>
          </div>
          <div class="primary-content-right">
            <img src="https://oes-hosted-images-public.s3.amazonaws.com/blue-solar.jpg" />
          </div>
        </div>

        <div class="step-container section-break">
          <p class="preheader">{{ 'offtaker.list.step-1' | translate }}</p>
          <h2>{{ 'offtaker.list.add-sites' | translate }}</h2>
          <h4>{{ 'offtaker.list.add-sites-individually' | translate }}</h4>
          <button class="btn btn-primary btn-margin" (click)="selectTab('offtakers')">{{ 'offtaker.list.add-an-offtaker' | translate }}</button>

          <div class="step-features">
            <div class="step-feature">
              <h4>{{ 'offtaker.list.add-offtakers' | translate }}</h4>
              <p>{{ 'offtaker.list.group-sites' | translate }}</p>
            </div>
            <div class="step-feature">
              <h4>{{ 'offtaker.list.define-groups' | translate }}</h4>
              <p>{{ 'offtaker.list.multiple-sites' | translate }}</p>
            </div>
            <div class="step-feature">
              <div class="feature-note">{{ 'offtaker.list.coming-soon' | translate }}</div>
              <h4>{{ 'offtaker.list.detailed-projects' | translate }}</h4>
              <p>{{ 'offtaker.list.detailed-sites' | translate }}</p>
            </div>
          </div>
        </div>

        <div class="step-container section-break">
          <p class="preheader">{{ 'offtaker.list.step-2' | translate }}</p>
          <h2>{{ 'offtaker.list.submit-proposals' | translate }}</h2>
          <h4>{{ 'offtaker.list.finance-providers' | translate }}</h4>
          <button class="btn btn-primary btn-margin" (click)="goToPortfolios()">{{ 'offtaker.list.submit-portfolio' | translate }}</button>
        </div>

        <div class="secondary-content-container section-break">
          <div class="secondary-content-left">
            <div class="feature-note">{{ 'offtaker.list.coming-soon' | translate }}</div>
            <h2>{{ 'offtaker.list.run-models' | translate }}</h2>
            <h4>{{ 'offtaker.list.more-tools' | translate }}</h4>

            <div class="secondary-feature-box">
              <h4>{{ 'offtaker.list.key-metrics' | translate }}</h4>
              <p>{{ 'offtaker.list.total-size' | translate }}</p>
            </div>

            <div class="secondary-feature-box">
              <h4>{{ 'offtaker.list.site-group-models' | translate }}</h4>
              <p>{{ 'offtaker.list.view-models' | translate }}</p>
            </div>

            <div class="secondary-feature-box">
              <h4>{{ 'offtaker.list.historical-models' | translate }}</h4>
              <p>{{ 'offtaker.list.rerun-model' | translate }}</p>
            </div>

          </div>
          <div class="secondary-content-right">
            <img src="https://oes-hosted-images-public.s3.amazonaws.com/ci-fin-model.jpg" />
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <button class="btn btn-grey" (click)="scrollToTop()">
            {{ 'offtaker.list.back-to-top' | translate }}
            <img src="/assets/icons/line-icons/shared/grey/arrow-up.svg" />
          </button>
        </div>

      </tab>

      <tab [active]="activeTab === 'offtakers'"
           (selectTab)="selectTab('offtakers')"
           heading="{{ 'offtaker.list.offtakers' | translate }}"
           index="1">
        <!-- Action buttons -->
        <oes-offtakers-list-action
          [offtakers]="offtakers"
          (createOfftaker)="createModal.show()"
          (createPortfolio)="createPortfolio($event)"
          (deleteProjectSets)="prepareDelete($event)">
        </oes-offtakers-list-action>
        <oes-grid-sub-action
          class="project-list-sub-actions"
          *ngIf="gridApi"
          [gridState]="gridState"
          [defaultGridState]="defaultGridState"
          (action)="subAction($event)">
        </oes-grid-sub-action>

        <!-- C&I Customers List -->
        <div class="grid-container">
          <div class="ag-grid-header-sticky" oesAgHorizontalScroll>
            <ag-grid-angular
              #C&ICustomersList
              id="C&ICustomersList"
              style="width: auto; min-height: 400px;"
              class="grid ag-theme-alpine"
              [gridOptions]="gridOptions"
              (gridReady)="onGridReady($event)"
              (selectionChanged)="selectionChanged($event)"
              (filterChanged)="gridStatusChanged($event, 'filter')"
              (sortChanged)="gridStatusChanged($event, 'sort')"
              (displayedColumnsChanged)="gridStatusChanged($event, 'column')">
            </ag-grid-angular>
          </div>
          <div *ngIf="!offtakers?.length" class="no-customers-overlay">
            <div class="content-container">
              <div class="image-container">
                <img src='/assets/icons/line-icons/shared/grey/book.svg' width='20px' />
              </div>
              <h5>{{ 'offtaker.list.none-added' | translate }}</h5>
              <p>{{ 'offtaker.list.none-added-text' | translate }}</p>
              <a href="#" [routerLink]="['./new']">{{ 'offtaker.list.add-first' | translate }}</a>
            </div>
          </div>
        </div>
      </tab>
    </tabset>

  </div>
</div>

<!-- Create modal (Full screen) -->
<div bsModal #createModal="bs-modal"
     [config]="{ignoreBackdropClick:true,keyboard:true}"
     class="modal" tabindex="-1" role="dialog"
     aria-labelledby="myLargeModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-full">
    <div class="modal-content">
      <oes-offtaker-create
        (close)="hideCreateModal()"></oes-offtaker-create>
    </div>
  </div>
</div>
