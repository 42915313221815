import { BaseModel } from '@shared/base.model';
import { OfftakerCardinality } from './offtaker-cardinality.enum';
import { OfftakerType } from './offtaker-type.enum';
import { ProjectSet } from './project-set/project-set.model';

export class Offtaker extends BaseModel<Offtaker> {
  cardinality: OfftakerCardinality;
  name: string;
  type: OfftakerType;
  projectSets: ProjectSet[];

  constructor(offtakerInfo: any) {
    super();
    this.id = offtakerInfo.id;
    this.cardinality = offtakerInfo.cardinality;
    this.name = offtakerInfo.name;
    this.type = offtakerInfo.type;
    this.projectSets = offtakerInfo.projectSets?.map(ps => new ProjectSet(ps));
  }
}
