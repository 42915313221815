import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject } from 'rxjs';

import { Theme } from './theme.enum.';

@Injectable({
  providedIn: 'root'
})
export class ThemesService {
  private _renderer: Renderer2;
  private themeSource = new BehaviorSubject<string>(Theme.ODYSSEY);
  public themeObservable = this.themeSource.asObservable();
  private currentTheme: Theme;

  constructor(private rendererFactory: RendererFactory2) {
    this._renderer = this.rendererFactory.createRenderer(null, null);

  }

  public getAuthClientId() {
    if (Theme.ODYSSEY === this.getTheme()) {
      return `oes-${environment.keycloak.theme}`;
    }
    return `oes-${this.getTheme().toString()}`;
  }

  /**
   * simply return subdomain (theme)
   */
  getTheme(): Theme {
    const splitUrl = location.host.split('.')[0].split('-');
    let theme = Theme.ODYSSEY;
    if (environment.name === 'dev' || environment.name === 'qa') {
      const storedTheme = this.getThemeFromLocalStorage();
      if (storedTheme !== null) {
        theme = storedTheme;
        return theme;
      }
    }
    if (splitUrl.includes('ecreee') || splitUrl.includes('ecr')) {
      theme = Theme.ECREEE;
    } else if (splitUrl.includes('rensource')) {
      theme = Theme.RENSOURCE;
    } else if (splitUrl.includes('earf')) {
      theme = Theme.EARF;
    } else if (splitUrl.includes('somaliland')) {
      theme = Theme.SOMALILAND;
    } else if (splitUrl.includes('avorenewables')) {
      theme = Theme.AVORENEWABLES;
    } else if (splitUrl.includes('amader')) {
      theme = Theme.AMADER;
    } else if (splitUrl.includes('burundi')) {
      theme = Theme.BURUNDI;
    } else if (splitUrl.includes('amap')) {
      theme = Theme.AMAP;
    } else if (splitUrl.includes('ager')) {
      theme = Theme.AGER;
    } else if (splitUrl.includes('rmi')) {
      theme = Theme.RMI;
    } else if (splitUrl.includes('sunafrica')) {
      theme = Theme.SUNAFRICA;
    } else if (splitUrl.includes('cotedivoire') && splitUrl.includes('gbe')) {
      theme = Theme.GBECOTEDIVOIRE;
    } else if (splitUrl.includes('benin') && splitUrl.includes('gbe')) {
      theme = Theme.GBEBENIN;
    } else if (splitUrl.includes('benin')) {
      theme = Theme.BENIN;
    } else if (splitUrl.includes('brilho')) {
      theme = Theme.BRILHO;
    } else if (splitUrl.includes('funae')) {
      theme = Theme.FUNAE;
    } else if (splitUrl.includes('dares')) {
      theme = Theme.DARES;
    } else if (splitUrl.includes('phares')) {
      theme = Theme.PHARES;
    } else if (splitUrl.includes('dream') && splitUrl.includes('ethiopia')) {
      theme = Theme.DREAMETHIOPIA;
    } else if (splitUrl.includes('esmap') && splitUrl.includes('hfe')) {
      theme = Theme.ESMAPHFE;
    } else if (splitUrl.includes('tchad')) {
      theme = Theme.TCHAD;
    } else if (splitUrl.includes('reamas') || splitUrl.includes('rearef')) {
      theme = Theme.REAREF;
    } else if (splitUrl.includes('reaeei')) {
      theme = Theme.REAEEI;
    } else if (splitUrl.includes('rea')) {
      theme = Theme.REA;
    } else if (splitUrl.includes('anper')) {
      theme = Theme.ANPER;
    } else if (splitUrl.includes('aser')) {
      theme = Theme.ASER;
    } else if (splitUrl.includes('aber')) {
      theme = Theme.ABER;
    } else if (splitUrl.includes('arene')) {
      theme = Theme.ARENE;
    } else if (splitUrl.includes('nerc')) {
      theme = Theme.NERC;
    } else if (splitUrl.includes('essor')) {
      theme = Theme.ESSOR;
    } else if (splitUrl.includes('paop')) {
      theme = Theme.PAOP;
    } else if (splitUrl.includes('ipff')) {
      theme = Theme.IPFF;
    } else if (splitUrl.includes('cpi')) {
      theme = Theme.CPI;
    } else if (splitUrl.includes('ogef')) {
      theme = Theme.OGEF;
    } else if (splitUrl.includes('ceiafrica')) {
      theme = Theme.CEIAFRICA;
    } else if (splitUrl.includes('powertrust')) {
      theme = Theme.POWERTRUST;
    } else if (splitUrl.includes('rrealiberia')) {
      theme = Theme.RREALIBERIA;
    } else if (splitUrl.includes('omdf')) {
      theme = Theme.OMDF;
    } else if (splitUrl.includes('a2ei')) {
      theme = Theme.A2EI;
    } else if (splitUrl.includes('epp')) {
      theme = Theme.EPP;
    } else if (splitUrl.includes('ifc')) {
      theme = Theme.IFC;
    } else if (splitUrl.includes('db')) {
      theme = Theme.DB;
    } else if (splitUrl.includes('cb')) {
      theme = Theme.CB;
    } else if (splitUrl.includes('nea')) {
      theme = Theme.NEA;
    } else if (splitUrl.includes('eeu')) {
      theme = Theme.EEU;
    } else if (splitUrl.includes('uef')) {
      theme = Theme.UEF;
    }
    return theme;
  }

  public setTheme(theme: Theme) {
    if (environment.name === 'dev' || environment.name === 'qa') {
      this.saveToThemeToLocalStorage(theme);
    }
    this._renderer.removeClass(document.body, this.currentTheme + '-theme');
    this._renderer.addClass(document.body, theme + '-theme');
    this.themeSource.next(theme);
    this.currentTheme = theme;
  }

  private saveToThemeToLocalStorage(theme: Theme) {
    localStorage.setItem('oes-theme', theme);
  }

  private getThemeFromLocalStorage(): Theme {
    const theme = localStorage.getItem('oes-theme');
    if (!theme) {
      return null;
    }
    return theme as Theme;
  }
}
