import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PROGRAM_MODULE_FLAGS } from '@program/shared/program-module-flags.enum';
import { ProgramProjectRules } from '@program/shared/program-project-rules.model';
import { PROGRAM_RULE_TYPES } from '@program/shared/program-rule-types.enum';
import { ProgramService } from '@program/shared/program.service';
import { ProjectFinancialModel } from '@project/detail/financials/project-financial-model.model';
import { ModulePermissions } from '@project/shared/module-permissions.model';
import { ProjectConnection } from '@project/shared/project-connection.model';
import { ProjectConnectionService } from '@project/shared/project-connection.service';
import { Project } from '@project/shared/project.model';
import { DeveloperProfileService } from '@shared/components/developer-profiles/developer-profile.service';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { EventService } from '@shared/event.service';
import { Organization } from '@shared/models/organization/organization.model';
import { IMultiSelectTexts } from 'ngx-bootstrap-multiselect';
import { UserService } from '@user/user.service';
import { take } from 'rxjs';
import { ROLE_TYPE } from '../../../../user/role-type';
import { User } from '@user/user.model';

const ciCategoryRuleMapper = {
  shareProjectOverviewData: 'overviewModuleRequirementFlag',
  shareProjectSpecificationData: 'informationModuleRequirementFlag',
  shareProjectSummaryData: 'summaryModuleRequirementFlag',
  shareProjectFiles: 'projectFilesModuleRequirementFlag',
  shareMilestoneData: 'milestonesModuleRequirementFlag',
  shareAnalyticsData: 'analyticsDataRequirementFlag'
};

@Component({
  selector: 'oes-assign-project-developer',
  templateUrl: './assign-project-developer.component.html',
  styleUrls: ['./assign-project-developer.component.scss']
})
export class AssignProjectDeveloperComponent implements OnInit {
  @Input() projectFinancialModel: ProjectFinancialModel;
  @Input() project: Project;

  @ViewChild('assignDeveloperModal', {static: false}) assignDeveloperModal: ModalDialogComponent;

  developers: any[];
  displayKeys: string[] = [];
  formGroup: UntypedFormGroup;
  modulePermissions: ModulePermissions;
  projectConnection: ProjectConnection;
  projectRule: ProgramProjectRules;
  selectedDeveloper: any;
  selectedRole: ROLE_TYPE;
  user: User;

  constructor(private _projectConnectionService: ProjectConnectionService,
              private _eventService: EventService,
              private _translateService: TranslateService,
              private _userService: UserService,
              private _programService: ProgramService,
              private _developerProfileService: DeveloperProfileService) {
  }

  ngOnInit(): void {
    this.selectedRole = this._userService.getSelectedRole();
    this._userService.getCurrentUser()
      .pipe(take(1))
      .subscribe((user: User) => {
        this.user = user;
      });
    this.getDevelopers();
  }

  getFilteredDevelopers(searchText: string) {
    this.getDevelopers(searchText);
  }

  private getDevelopers(searchText: string = null) {
    this._developerProfileService.list(searchText)
    .pipe(take(1))
    .subscribe((developers: Organization[]) => {
      this.developers = developers.map(developer => {
        return {
          id: developer.id,
          name: developer.name
        };
      });
      this.getProjectRule();
    });
  }

  private getProjectRule() {
    if (this.project?.program?.id) {
      this._programService.getProgramRules(this.project.program.id, PROGRAM_RULE_TYPES.PROJECT_RULE)
        .pipe(take(1))
        .subscribe((rule: ProgramProjectRules) => {
          this.projectRule = rule;
          this.findProjectDeveloperConnection();
        });
      } else {
        this.projectRule = new ProgramProjectRules({});
        this.findProjectDeveloperConnection();
    }
  }

  private findProjectDeveloperConnection() {
    this._projectConnectionService.getProjectConnections(this.project?.id, { page: 0, size: 100, sort: 'created,DESC', primaryConnection: true })
      .pipe(take(1))
      .subscribe((projectConnections: ProjectConnection[]) => {
        this.projectConnection = projectConnections[0];
        this.buildForm(this.projectRule);
      });
  }

  private buildForm(rule: ProgramProjectRules) {
    const controls = [];
    this.displayKeys = [];
    const keys = Object.keys(ciCategoryRuleMapper);
    keys.forEach(key => {
      if (rule[ciCategoryRuleMapper[key]] !== PROGRAM_MODULE_FLAGS.NOT_REQUIRED) {
        const existingValue = this.projectConnection ? this.projectConnection[key] : true;
        controls.push({ [key]: new UntypedFormControl(existingValue, Validators.required) });
        this.displayKeys.push(key);
      }
    });
    this.selectedDeveloper = this.developers?.find(dev => dev?.id === this.projectConnection?.recipient?.id);
    const selectOption = { selectOption: new UntypedFormControl([this.selectedDeveloper?.id], Validators.required) };
    this.formGroup = new UntypedFormGroup(
      Object.assign({}, selectOption, ...controls)
    );
    this.subscribeForm();
  }

  private subscribeForm() {
    this.formGroup.valueChanges.subscribe((values: any) => {
      this.selectedDeveloper = values?.selectOption;
    });
  }

  assignDeveloper() {
    const developerId = this.selectedDeveloper[0];
    const projectConnection = new ProjectConnection({
      projectInfo: this.project,
      recipient: new Organization({ id: developerId }),
      shareProjectOverviewData: this.formGroup.controls.shareProjectOverviewData?.value || false,
      shareProjectSpecificationData: this.formGroup.controls.shareProjectSpecificationData?.value || false,
      shareProjectSummaryData: this.formGroup.controls.shareProjectSummaryData?.value || false,
      shareProjectFiles: this.formGroup.controls.shareProjectFiles?.value || false,
      shareMilestoneData: this.formGroup.controls.shareMilestoneData?.value || false,
      shareAnalyticsData: this.formGroup.controls.shareAnalyticsData?.value || false,
      primaryConnection: true
    });
    this._projectConnectionService.shareProject(this.project?.id, developerId, projectConnection)
      .pipe(take(1))
      .subscribe((savedConnection: ProjectConnection) => {
        this.hideAssignDeveloperModal();
        this.getProjectRule();
        this._eventService.success('Developer assigned/updated.');
      });
  }

  openAssignDeveloperModal() {
    this.assignDeveloperModal.show();
  }

  hideAssignDeveloperModal() {
    this.assignDeveloperModal.hide();
  }
}
