<div class="portfolio-list">
  <div class="oes-grid d-flex justify-content-end">
    <div class="sub-action-container">
      <oes-grid-sub-action
        *ngIf="gridApi"
        [gridState]="gridState"
        [defaultGridState]="defaultGridState"
        (action)="subAction($event)">
      </oes-grid-sub-action>
    </div>
  </div>
  <div class="ag-grid-header-sticky" oesAgHorizontalScroll>
    <ag-grid-angular
      #C&IInvestorPortfoliosList
      id="C&ICustomersList"
      style="width: auto; min-height: 400px;"
      class="grid ag-theme-alpine"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      (cellValueChanged)="onCellValueChanged($event)"
      (filterChanged)="gridStatusChanged($event, 'filter')"
      (sortChanged)="gridStatusChanged($event, 'sort')"
      (displayedColumnsChanged)="gridStatusChanged($event, 'column')">
    </ag-grid-angular>
  </div>
</div>
