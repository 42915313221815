import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { View } from '@shared/components/view-toggle-button/view.enum';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ProjectType } from '@project/shared/project-type.enum';
import { Offtaker } from '../offtaker.model';
import { ProjectSet } from '../project-set/project-set.model';
import { SelectedProjectSetsService } from './selected-project-sets.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProjectSetPortfolio } from '../project-set-portfolio/project-set-portfolio.model';
import { ProgramService } from '@program/shared/program.service';
import { Program, ProgramSubmissionType } from '@program/shared/program.model';
import { IMultiSelectTexts } from 'ngx-bootstrap-multiselect';

@Component({
  selector: 'oes-offtakers-list-action',
  templateUrl: './offtakers-list-action.component.html',
  styleUrls: ['./offtakers-list-action.component.scss']
})
export class OfftakersListActionComponent implements OnInit, OnDestroy {
  @ViewChild('deleteModal', {static: false}) public deleteModal: ModalDirective;
  @ViewChild('createPortfolioModal', {static: false}) public createPortfolioModal: ModalDirective;

  @Input() offtakers: Offtaker[] = [];

  @Output() createOfftaker: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deleteProjectSets: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() createPortfolio: EventEmitter<ProjectSetPortfolio> = new EventEmitter<ProjectSetPortfolio>();

  approvedPrograms: Program[];
  createPortfolioFormGroup: UntypedFormGroup;
  customText: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'item selected',
    checkedPlural: 'items selected',
    searchPlaceholder: 'Find',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Select program',
    allSelected: 'All selected',
  };
  projectType = ProjectType;
  selectedIds: string[] = [];
  selectedProjectSets: ProjectSet[] = [];
  view = View;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _selectedProjectSetsService: SelectedProjectSetsService,
              private _programService: ProgramService) {
  }

  ngOnInit() {
    this._selectedProjectSetsService.projectSets$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((projectSets: ProjectSet[]) => {
      this.selectedProjectSets = projectSets;
      this.selectedIds = projectSets?.map(item => item.id);
      this.findApprovedPrograms();
    });

    this.buildCreatePortfolioForm();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  emitCreateOfftaker() {
    this.createOfftaker.emit(true);
  }

  emitDeleteProjectSets() {
    this.deleteProjectSets.emit(this.selectedIds);
    this.deleteModal.hide();
  }

  emitCreatePortfolio() {
    const programId = this.createPortfolioFormGroup.controls['selectOption']?.value?.length ? this.createPortfolioFormGroup.controls['selectOption']?.value[0] : null;
    const emitThis = new ProjectSetPortfolio ({
      name: this.createPortfolioFormGroup.controls['name'].value,
      projectSets: this.selectedIds.map(id => new ProjectSet({id})),
      program: programId ? new Program({ id: programId }) : null
    });
    this.createPortfolio.emit(emitThis);
    this.createPortfolioModal.hide();
  }

  approvedProgramsChanged(event) {
  }

  private findApprovedPrograms() {
    this._programService.getApprovedByType(ProgramSubmissionType.C_I)
      .pipe(take(1))
      .subscribe((programs: Program[]) => {
        this.approvedPrograms = programs;
      });
  }

  private buildCreatePortfolioForm() {
    this.createPortfolioFormGroup = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      connectProgram: new UntypedFormControl('false', Validators.required),
      selectOption: new UntypedFormControl(null)
    });
  }
}
