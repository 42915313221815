import { Component, OnDestroy, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { CiPortfolioListGridSettingService } from '../ci-portfolio-list-grid-setting.service';
import { GridSubAction } from '@shared/ag-grid/component/sub-action/sub-action.enum';
import { GridState } from '@shared/ag-grid/gird-state';
import { InvestorProjectSetPortfolioService } from '@program/program-detail/program-ci-portfolio-submissions/investor-project-set-portfolio-connection.service';
import { ProjectSetPortfolioConnection } from '@finance/developer-project-set-portfolio/developer-project-set-portfolio-connection.model';
import { ProjectSet } from '@project/offtakers/project-set/project-set.model';

@Component({
  selector: 'oes-ci-portfolio-list-display',
  templateUrl: './ci-portfolio-list-display.component.html',
  styleUrls: ['./ci-portfolio-list-display.component.scss']
})
export class CiPortfolioListDisplayComponent implements OnInit, OnChanges, OnDestroy {
  @Input() includeProgram: boolean = true;
  @Input() projectSets: ProjectSet[] = [];
  @Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();

  columnApi: ColumnApi;
  defaultGridState: GridState;
  gridApi: GridApi;
  gridOptions: GridOptions;
  gridState: GridState;
  localTimeZone: string;
  ngUnsubscribe: Subject<any> = new Subject();

  private gridStateStorageKey: string;

  constructor(public _ciPortfolioListGridSettingService: CiPortfolioListGridSettingService,
              private _investorProjectSetPortfolioService: InvestorProjectSetPortfolioService) {
              }

  ngOnInit() {
    this.getGridOptions(this.includeProgram);
    this.gridStateStorageKey = this._ciPortfolioListGridSettingService.buildGridStateStorageKey('ciPortfolioList' + '?include-program=' + this.includeProgram);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.projectSets && this.gridApi) {
      this.displayData(this.projectSets);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this._ciPortfolioListGridSettingService.setGridApi(params.api, params.columnApi);
    this.defaultGridState = this._ciPortfolioListGridSettingService.buildDefaultGridState();
  }

  gridStatusChanged(event, type) {
    this.storeGridState();
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._ciPortfolioListGridSettingService.storeGridStateByApis(this.gridStateStorageKey, this.gridApi, this.columnApi);
    }
  }

  onCellValueChanged(event: any) {
    if (event?.data?.status) {
      const status = event.data.status;
      const connectionId = event.node?.allLeafChildren[0]?.data?.portfolioConnectionId;
      this._investorProjectSetPortfolioService.updateStatus(connectionId, status)
        .pipe(take(1))
        .subscribe((res: ProjectSetPortfolioConnection) => {
          this.reload.next(true);
        });
    }
  }

  subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList:
        this.exportCsv();
        break;
      case GridSubAction.reload:
        this.reload.next(true);
        break;
      case GridSubAction.clearFilter:
        this._ciPortfolioListGridSettingService.clearStoredGridState(this.gridStateStorageKey);
        break;
    }
  }

  private displayData(projectSets: ProjectSet[]) {
    if (projectSets?.length > 0) {
      this.gridApi.setRowData(projectSets);
      this._ciPortfolioListGridSettingService.applyStoredGridState(this.gridStateStorageKey, this.defaultGridState);
      this.gridApi.hideOverlay();
    } else {
      this.gridApi.showNoRowsOverlay();
    }
  }

  private exportCsv() {
    const processCellCallback = (params: any) => {
      if (params.value && params.value && params.value.contactFirstName && params.value.contactLastName) {
        return params.value.contactFirstName + ' ' + params.value.contactLastName;
      }
      return params.value;
    };
    this._ciPortfolioListGridSettingService.exportCsv(this.gridApi, 'C&I Portfolios', null, processCellCallback);
  }

  private getGridOptions(includeProgram: boolean) {
    this.gridOptions = this._ciPortfolioListGridSettingService.getGridOptions(includeProgram);
      this.gridOptions['context'] = {
        gridService: this
      };
  }
}
