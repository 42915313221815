import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProjectSet } from '../project-set/project-set.model';

@Injectable({
  providedIn: 'root'
})
export class SelectedProjectSetsService {
  private _projectSetsSubject = new BehaviorSubject<ProjectSet[]>([]);
  public projectSets$: Observable<ProjectSet[]> = this._projectSetsSubject.asObservable();

  constructor() {
  }

  get projectSets(): ProjectSet[] {
    return this._projectSetsSubject.getValue();
  }

  set projectSets(projectSets: ProjectSet[]) {
    this._projectSetsSubject.next(projectSets);
  }

  clear() {
    this._projectSetsSubject.next([]);
  }
}
