import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'oes-portfolio-grid-status-cell',
  templateUrl: './portfolio-grid-status-cell.component.html',
  styleUrls: ['./portfolio-grid-status-cell.component.scss']
})
export class PortfolioGridStatusCellComponent implements INoRowsOverlayAngularComp {
  name = '';
  nativeStatuses = [];
  paramsActive = false;  // to show fa-caret-down icon

  constructor() {
  }

  agInit(params): void {
    if (params?.node?.allLeafChildren && params.node.allLeafChildren[0].data?.status) {
      this.name = params.node.allLeafChildren[0].data.status;
      this.paramsActive = true;
    } else {
      this.name = null;
      this.paramsActive = false;
    }
  }
}
