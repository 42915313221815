import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'oes-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent {
  @ViewChild('modalTemplate', {static: false}) modalTemplate: ModalDirective;

  @Input() activateSubmit = false;
  @Input() allowCancel = true;
  @Input() allowDelete = false;
  @Input() allowSubmit = true;
  @Input() cancelButtonText: string;
  @Input() deleteButtonText: string;
  @Input() destructive = false;
  @Input() hideCloseBtn = false;
  @Input() modalStyle: string = ''; // fresh, default: ''
  @Input() size: number | string = ''; // small, medium, large, x-large and '' is unset
  @Input() submitButtonText: string;
  @Input() takeUserBack = false;
  @Input() takeUserBackSteps: number = 1;
  @Input() title = '';

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() delete: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() submit: EventEmitter<boolean> = new EventEmitter<boolean>();

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: true
  };
  modalRef: BsModalRef;

  constructor(private _location: Location) {
  }

  show() {
    this.modalTemplate.show();
  }


  hide(goBack = false) {
    if (this.modalTemplate.isShown) {
      if (goBack) {
        this.takeBack();
      }
      this.modalTemplate.hide();
    }
  }

  clickDelete() {
    this.delete.emit(true);
  }

  clickSubmit() {
    this.submit.emit(true);
  }

  maxWidth() {
    if (typeof this.size === 'number') {
      return {'max-width': this.size + '%'};
    }
  }

  setSize() {
    if (typeof this.size !== 'number') {
      let size = '';
      switch (this.size) {
        case 'small':
          size = 'modal-sm';
          break;
        case 'medium':
          size = 'modal-md';
          break;
        case 'large':
          size = 'modal-lg';
          break;
        case 'x-large':
          size = 'x-large';
          break;
      }
      return size;
    }
  }

  onHide(event) {
    this.close.emit(true);
  }

  takeBack() {
    if (this.takeUserBack) {
      for (let i = this.takeUserBackSteps; i--; i > 0) {
        this._location.back();
      }
    }
  }

  get status(): boolean {
    return this.modalTemplate.isShown;
  }
}
