import { Injectable } from '@angular/core';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Offtaker } from './offtaker.model';
import { OfftakersApiConstants } from './offtakers.constant';

@Injectable({
  providedIn: 'root'
})
export class OfftakerService extends BaseRestApiService {
  private _offtaker: Offtaker;
  private _offtakerId: string;

  set offtaker(offtaker: Offtaker) {
    this._offtaker = offtaker;
  }

  get offtaker() {
    return this._offtaker;
  }

  set offtakerId(offtakerId: string) {
    this._offtakerId = offtakerId;
  }

  get offtakerId() {
    return this._offtakerId;
  }

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public detail(offtakerId: string): Observable<Offtaker> {
    return this.get<Offtaker>(OfftakersApiConstants.offtaker.detail(offtakerId))
      .pipe(map((offtaker: Offtaker) => {
        return new Offtaker(offtaker);
      }));
  }

  public list(params: any = {page: 0, size: 1000}): Observable<Offtaker[]> {
    return this.get<Offtaker[]>(OfftakersApiConstants.offtaker.list(), {params: params})
      .pipe(map((offtakers: Offtaker[]) => {
        return offtakers.map(offtaker => new Offtaker(offtaker));
      }));
  }


  public create(offtaker: Offtaker): Observable<Offtaker> {
    return this.post(OfftakersApiConstants.offtaker.create(), JSON.stringify(offtaker))
      .pipe(map((response: Offtaker) => {
        return new Offtaker(response);
      }));
  }

  public update(offtaker: Offtaker): Observable<Offtaker> {
    return this.put(OfftakersApiConstants.offtaker.update(offtaker.id), JSON.stringify(offtaker))
      .pipe(map((response: Offtaker) => {
        return new Offtaker(response);
      }));
  }

  public remove(offtakerId: string): Observable<any> {
    return this.delete(OfftakersApiConstants.offtaker.delete(offtakerId));
  }

}
