<div class="section-border">

  <ng-container *ngIf="selectedRole === 'FINANCE_USER'; else NonFinanceUser">
    <div class="developer-container">
      <div class="developer">
        <h5>{{ 'project.overview.developer' | translate }}</h5>
        <h4>{{ projectConnection?.recipient?.name || '--' }}</h4>
      </div>
      <div class="assign">
        <button *ngIf="!projectConnection" class="btn btn-primary-light" (click)="openAssignDeveloperModal()">
          {{ 'project.overview.assign-developer' | translate }}
        </button>
        <button *ngIf="projectConnection" class="btn btn-grey" (click)="openAssignDeveloperModal()">
          <img style="width: 15px; height: 15px;" src="assets/icons/line-icons/shared/black/change.svg" role="presentation" />
          {{ 'buttons.change' | translate }}
        </button>
      </div>
    </div>
    <div class="line-break"></div>
    <div class="manage-container">
      <button class="btn btn-text-only"
              (click)="openAssignDeveloperModal()"
              [disabled]="!projectConnection">
        {{ 'project.overview.manage-permissions' | translate }}
      </button>
    </div>
  </ng-container>

  <ng-template #NonFinanceUser>
    <div class="developer-container">
      <div class="developer">
        <h5>{{ 'project.overview.developer' | translate }}</h5>
        <h4>{{ user?.organization?.name }}</h4>
      </div>
    </div>
  </ng-template>
</div>

<!-- Assign Developer Modal -->
<oes-modal-dialog
  #assignDeveloperModal
  [title]="'project.overview.assign-developer' | translate"
  [submitButtonText]="'buttons.save'| translate"
  [size]="'small'"
  [allowSubmit]="formGroup?.valid"
  [submitButtonText]="'buttons.confirm' | translate"
  (submit)="assignDeveloper()">
  <form *ngIf="formGroup" [formGroup]="formGroup" class="dialog-form">
    <div class="developer-dropdown">
      <oes-dropdown
        id="developerSelector"
        (searchChanged)="getFilteredDevelopers($event)"
        [lazySearch]="true"
        [parentFormGroup]="formGroup"
        [items]="developers"
        [selection]="'single'"
        [enableSearch]="true"
        [enableUncheckAll]="false">
      </oes-dropdown>
      <span *ngIf="!selectedDeveloper">{{ 'project.overview.select-developer' | translate }}</span>
    </div>
    <div class="line-break"></div>
    <h5>{{ 'project.overview.manage-viewing-permissions' | translate }}</h5>
    <p>{{ 'project.overview.choose-permissions' | translate }}</p>
    <div class="permissions-container" [ngClass]="{ 'opacity-50' : !selectedDeveloper }">
      <ng-container *ngFor="let control of displayKeys">
        <div class="input-switch-container">
          <span class="input-label">
            {{ 'sharing.permissions.' + control | translate }}
          </span>
          <label class="oes-switch my-1">
            <input
              type="checkbox"
              [formControlName]="control"
              [name]="control">
            <span class="oes-slider"></span>
          </label>
        </div>
      </ng-container>
    </div>
  </form>
</oes-modal-dialog>
