import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Milestone } from "../milestone.model";
import { MilestoneStatus } from "../milestone-status.model";
import { MILESTONE_STATUS, MILESTONE_STATUS_LABEL } from "@program/shared/milestone-status.enum";
import { TranslateService } from "@ngx-translate/core";
import { MilestoneService } from "../milestone.service";
import { MILESTONE_TYPE } from "../milestone-type.enum";
import { Project } from "@project/shared/project.model";
import { Subscription } from "rxjs";

@Component({
  selector: 'oes-milestone-row',
  templateUrl: './milestone-row.component.html',
  styleUrls: ['./milestone-row.component.scss'],
})
export class MilestoneRowComponent implements OnInit, OnDestroy {
  @Input() index: number;
  @Input() milestoneId: string;
  @Input() milestoneType: MILESTONE_TYPE;
  @Input() project: Project;
  @Input() statusDropdownOptions: any[];

  milestoneForm: any;
  initialStatusSelection: any;
  milestone: Milestone = new Milestone({});
  milestoneStatus: MilestoneStatus;
  milestoneViewAfterAnimation = false;
  milestoneViewShowing: boolean;
  milestoneTypes = MILESTONE_TYPE;

  private programMilestoneDataSubscription: Subscription;
  private projectMilestoneDataSubscription: Subscription;
  private deleteMilestoneSubscription: Subscription;

  private getMilestoneSubscription: Subscription;

  constructor(private _milestoneService: MilestoneService,
              private _translateService: TranslateService) {
    this.statusChanged = this.statusChanged.bind(this);
  }

  ngOnInit() {
    this.deleteMilestoneSubscription = this._milestoneService.deleteMilestone$.subscribe(_ => {
      this.milestoneViewShowing = false;
    });
    this.getMilestoneSubscription = this._milestoneService.getMilestoneByIdWithForm(this.milestoneId)
    .subscribe(({ milestone, form }) => {
      if (!milestone) {
        return;
      }
      this.milestone = milestone;
      this.milestoneForm = form;
      this.initialStatusSelection = this.findInitialStatusDropdownSelection(this.milestone.status);
      this.milestoneStatus = new MilestoneStatus(this.milestone.id, this.milestone.status);
    });
  }

  ngOnDestroy(): void {
    if (this.deleteMilestoneSubscription) {
      this.deleteMilestoneSubscription.unsubscribe();
    }
    if (this.programMilestoneDataSubscription) {
      this.programMilestoneDataSubscription.unsubscribe();
    }
    if (this.projectMilestoneDataSubscription) {
      this.projectMilestoneDataSubscription.unsubscribe();
    }
    if (this.getMilestoneSubscription) {
      this.getMilestoneSubscription.unsubscribe();
    }
  }

  statusChanged(event) {
    if (event[0].value && event[0].value !== this.milestone?.status) {
      this.milestone = new Milestone({ ...this.milestone, status: event[0].value });
      this._milestoneService.updateMilestone(this.milestone);
      this.milestoneStatus = new MilestoneStatus(this.milestone?.id, this.milestone?.status);
      this.initialStatusSelection = this.findInitialStatusDropdownSelection(this.milestone?.status);
      this.milestoneStatus = new MilestoneStatus(this.milestone?.id, this.milestone?.status);
    }
  }

  findInitialStatusDropdownSelection(name: MILESTONE_STATUS): any {
    const translatedName = this._translateService.instant(MILESTONE_STATUS_LABEL[name]);
    return [this.statusDropdownOptions.find(status => {
      return status.name === translatedName;
    })];
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  openMilestoneView() {
    this.milestoneViewShowing = true;
    this.milestoneViewAfterAnimation = true;
  }

  closeMilestoneView() {
    this.milestoneViewShowing = false;
    setTimeout(() => {
      this.milestoneViewAfterAnimation = false;
    }, 500); // animation length 500ms
  }
}
