import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { ColDef, GridOptions, GridApi, ColumnApi } from 'ag-grid-community';
import { GridState } from '@shared/ag-grid/gird-state';
import { Injectable } from '@angular/core';
import { CellButtonComponent } from '@shared/ag-grid/component/cell-button/cell-button.component';

@Injectable({
  providedIn: 'root'
})
export class EvaluationsDisplayGridSettingService extends BaseAgGridSettingsService {

  _columnApi: ColumnApi;
  _gridApi: GridApi;
  _gridStateStorageKey: string;
  currentGridState: GridState = new GridState();
  defaultGridState: GridState = new GridState();
  initialGridState: GridState = new GridState();

  private columns = {};
  private pass = {};
  private fail = {};

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService) {
    super(_agGridServicesWrapperService);
    this.columns = _agGridServicesWrapperService._translateService.instant('program.configuration.evaluation-display.columns');
    this.pass = _agGridServicesWrapperService._translateService.instant('program.evaluation.PASS');
    this.fail = _agGridServicesWrapperService._translateService.instant('program.evaluation.FAIL');
  }

  getGridOptions(): GridOptions {
    return <GridOptions>{
      columnDefs: this.getColumnSetting(),
      components: {
        cellButtonComponent: CellButtonComponent
      },
      rowHeight: 60,
      sortable: false,
      suppressMenu: true,
      suppressRowClickSelection: true
    };
  }

  private getColumnSetting(): ColDef[] {
    return [
      {
        field: 'updated',
        headerName: this.columns['evaluation-date'],
        headerTooltip: this.columns['evaluation-date'],
        width: 170,
        cellRenderer: (params) => {
          return this.getLocalDate({value: params.value}, 'dd MMMM yyyy HH:mm');
        }
      },
      {
        field: 'evaluationConfig.name',
        headerName: this.columns['criteria'],
        headerTooltip: this.columns['criteria'],
        width: 170
      },
      {
        field: 'scoreValue',
        headerName: this.columns['score'],
        headerTooltip: this.columns['score'],
        width: 170,
        cellRenderer: (params) => {
          if (params?.data?.evaluationConfig?.type === 'SCORE' &&
              params?.data?.scoreValue !== null &&
              params?.data.evaluationConfig?.rangeEnd !== null) {
            return params.data.scoreValue + '/' + params.data.evaluationConfig.rangeEnd;
          }
          if (params?.data?.evaluationConfig?.type === 'PASS_FAIL' &&
              params?.data?.boolValue !== null) {
                const bool = params.data.boolValue;
                if (bool) {
                  return this.pass;
                }
                return this.fail;
              }
          return '--';
        }
      },
      {
        field: 'comments',
        headerName: this.columns['comments'],
        headerTooltip: this.columns['comments'],
        width: 170,
        autoHeight: true,
        wrapText: true,
        flex: 1,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word', 'line-height': 1.2, 'padding-top': '5px', 'padding-bottom': '5px' }
      },

    ];
  }

}
