import { Injectable } from "@angular/core";
import { Milestone } from "./milestone.model";
import { BehaviorSubject, Observable, Subject, combineLatest, map } from "rxjs";
import { MILESTONE_TYPE } from "./milestone-type.enum";
import { MilestoneConfigAction, MilestoneConfigEvent, MilestoneConfigInputs, MilestoneEditInputs, MilestoneFilesWrapper, MilestoneUpdateEvent } from "./milestone-util-types";

@Injectable({
  providedIn: 'root'
})
export class MilestoneService {
  constructor() {}

  private _filesAddedSubject = new BehaviorSubject<MilestoneFilesWrapper>({ files: [] });
  private _programMilestoneDataSubject = new BehaviorSubject<Milestone[]>(undefined);
  private _projectMilestoneDataSubject = new BehaviorSubject<Milestone[]>(undefined);
  private _submitUpdateSubject = new BehaviorSubject<MilestoneUpdateEvent>(undefined);
  private _submitCreateConfigSubject = new BehaviorSubject<MilestoneConfigEvent>(undefined);
  private _submitUpdateConfigSubject = new BehaviorSubject<MilestoneConfigEvent>(undefined);
  private _deleteMilestoneSubject = new BehaviorSubject<Milestone>(undefined);
  private _openEditMilestoneSubject = new BehaviorSubject<MilestoneEditInputs>(undefined);
  private _openMilestoneConfigSubject = new BehaviorSubject<MilestoneConfigInputs>(undefined);
  private _allMilestoneFormsSubject = new BehaviorSubject<{ [key: string]: object }>(undefined);
  private _refetchMilestonesSubject = new BehaviorSubject<void>(undefined);

  filesAdded$ = this._filesAddedSubject.asObservable();
  programMilestoneData$ = this._programMilestoneDataSubject.asObservable();
  projectMilestoneData$ = this._projectMilestoneDataSubject.asObservable();
  submitUpdate$ = this._submitUpdateSubject.asObservable();
  submitCreateConfig$ = this._submitCreateConfigSubject.asObservable();
  submitUpdateConfig$ = this._submitUpdateConfigSubject.asObservable();
  deleteMilestone$ = this._deleteMilestoneSubject.asObservable();
  openEditMilestone$ = this._openEditMilestoneSubject.asObservable();
  openMilestoneConfig$ = this._openMilestoneConfigSubject.asObservable();
  allMilestoneForms$ = this._allMilestoneFormsSubject.asObservable();
  refetchMilestones$ = this._refetchMilestonesSubject.asObservable();

  setMilestoneData(data: Milestone[], type: MILESTONE_TYPE) {
    if (type === MILESTONE_TYPE.PROGRAM) {
      this._programMilestoneDataSubject.next(data);
    } else {
      this._projectMilestoneDataSubject.next(data);
    }
  }

  setAllForms(forms: { [key: string]: object }) {
    this._allMilestoneFormsSubject.next(forms);
  }

  getMilestoneById(id: string): Observable<Milestone> {
    return combineLatest([this.programMilestoneData$, this.projectMilestoneData$]).pipe(
      map(([programMilestones, projectMilestones]) => {
        return programMilestones.concat(projectMilestones).find(milestone => milestone.id === id);
      })
    );
  }

  getMilestoneByIdWithForm(id: string): Observable<{ milestone: Milestone, form: any }> {
    return combineLatest([
      this.programMilestoneData$,
      this.projectMilestoneData$,
      this.allMilestoneForms$
    ]).pipe(
      map(([programMilestones, projectMilestones, allForms]) => {
        let milestone = null;
        let form = null;
        if (programMilestones?.length || projectMilestones?.length) {
          milestone = programMilestones.concat(projectMilestones).find(m => m.id === id);
          if (milestone && allForms !== undefined && Object.keys(allForms).length) {
            form = allForms[milestone.milestoneConfig.id];
          }
        } else {
        }
        return { milestone, form };
      })
    );
  }

  getMilestonesByType(type: MILESTONE_TYPE): Observable<Milestone[]> {
    return type === MILESTONE_TYPE.PROGRAM ? this.programMilestoneData$ : this.projectMilestoneData$;
  }

  updateMilestone(milestone: Milestone) {
    var currentData;
    var milestoneType;
    if (milestone.milestoneConfig.program && Object.keys(milestone.milestoneConfig.program).length) {
      currentData = this._programMilestoneDataSubject.getValue();
      milestoneType = MILESTONE_TYPE.PROGRAM;
    } else {
      currentData = this._projectMilestoneDataSubject.getValue();
      milestoneType = MILESTONE_TYPE.PROJECT;
    }
    const index = currentData.findIndex(m => m.id === milestone.id);
    if (index !== -1) {
      currentData[index] = milestone;
      this.setMilestoneData(currentData, milestoneType);
      this._submitUpdateSubject.next({
        milestone,
        filesAdded: this._filesAddedSubject.getValue(),
        milestoneType
      });
    }
  }

  updateFilesAdded(milestone: Milestone, files: MilestoneFilesWrapper) {
    this._filesAddedSubject.next(files);
    this.updateMilestone(milestone);
  }

  configMilestone(configEvent: MilestoneConfigEvent, action: MilestoneConfigAction) {
    if (action === 'create') {
      this._submitCreateConfigSubject.next(configEvent);
    } else if (action === 'edit') {
      this._submitUpdateConfigSubject.next(configEvent);
    }
  }

  deleteMilestone(milestone: Milestone) {
    this._deleteMilestoneSubject.next(milestone);
  }

  openEditMilestone(data: any) {
    this._openEditMilestoneSubject.next(data);
  }

  openMilestoneConfig(data: any) {
    this._openMilestoneConfigSubject.next(data);
  }

  triggerRefetchMilestones() {
    this._refetchMilestonesSubject.next();
  }
}
