import { Injectable } from '@angular/core';
import { ProjectSetPortfolioConnection } from '@finance/developer-project-set-portfolio/developer-project-set-portfolio-connection.model';
import { FinanceDealStatus } from '@project/offtakers/finance-deal-status.enum';
import { ProjectSetPortfolio } from '@project/offtakers/project-set-portfolio/project-set-portfolio.model';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InvestorProjectSetPortfolioConnectionApiConstants } from './investor-project-set-portfolio-connection.api.constant';

@Injectable({
  providedIn: 'root'
})
export class InvestorProjectSetPortfolioService extends BaseRestApiService {
  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  list(programId?: string): Observable<ProjectSetPortfolioConnection[]> {
    const params = { page: 0, size: 1000 };
    if (programId) {
      params['programId'] = programId;
    }
    return this.get<ProjectSetPortfolioConnection[]>(InvestorProjectSetPortfolioConnectionApiConstants.connections.list(), { params: params })
      .pipe(map((projectSetPortfolioConnections: ProjectSetPortfolioConnection[]) => {
        return projectSetPortfolioConnections.map(pspc => new ProjectSetPortfolioConnection(pspc))
          .sort((a, b) => {
            if (a.projectSetPortfolio.name >= b.projectSetPortfolio.name) {
              return 1;
            } else {
              return -1;
            }
          });
      }));
  }

  detail(projectSetPortfolioConnectionId: string): Observable<ProjectSetPortfolioConnection> {
    return this.get<ProjectSetPortfolioConnection>(InvestorProjectSetPortfolioConnectionApiConstants.connections.detail(projectSetPortfolioConnectionId))
      .pipe(map((pspc: ProjectSetPortfolioConnection) => {
        return new ProjectSetPortfolioConnection(pspc);
      }));
  }

  updateStatus(projectSetPortfolioConnectionId: string, status: FinanceDealStatus): Observable<ProjectSetPortfolioConnection> {
    return this.put<ProjectSetPortfolioConnection>(InvestorProjectSetPortfolioConnectionApiConstants.connections.updateStatus(projectSetPortfolioConnectionId, status));
  }



}
