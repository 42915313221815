<div class="offtaker-action">

  <!-- Create Offtaker -->
  <div class="top-page-heading">
    <h3>{{ 'offtaker.list.offtakers' | translate }}</h3>
    <div class="create-offtaker">
      <oes-button
        class="btn btn-primary btn-lg"
        (click)="emitCreateOfftaker()"
        data-testid="create-offtaker-button">
        {{ 'offtaker.create' | translate }}
      </oes-button>
    </div>
  </div>

  <div class="list-header d-flex flex-row justify-content-between align-items-center flex-wrap">
    <div class="d-flex flex-row align-items-center flex-wrap">
      <div class="action-buttons btn-group"
            data-testid="action-buttons"
            [tooltip]="selectedIds == null || selectedIds?.length == 0 ? ActionTipContent : null"
            placement="bottom"
            container="body">

        <!-- Create Portfolio -->
        <div class="action-button">
          <button type="button"
                  class="btn btn-primary action-button-left create-portfolio-button"
                  data-testid="create-portfolio-button"
                  (click)="createPortfolioModal.show()"
                  tooltip="{{ 'offtaker.actions.create-portfolio' | translate }}"
                  placement="top"
                  [disabled]="selectedIds == null || selectedIds?.length == 0">
            <div></div> <!-- This div here intentionally to preserve tooltip behavior from above.  Do not remove. -->
          </button>
        </div>

        <!-- Delete Project(s) -->
        <div class="action-button">
          <button type="button"
                class="btn btn-primary delete-project-sets-button"
                (click)="deleteModal.show()"
                data-testid="delete-project-sets-button"
                tooltip="{{ 'offtaker.actions.delete' | translate }}"
                placement="top"
                [disabled]="selectedIds == null || selectedIds?.length == 0">
            <div></div> <!-- This div here intentionally to preserve tooltip behavior from above.  Do not remove. -->
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #ActionTipContent>{{ 'offtaker.list.action-tip' | translate }}</ng-template>

<div class="modal fade" bsModal #deleteModal="bs-modal"
     [config]="{backdrop:true,ignoreBackdropClick:true,keyboard:true}"
     tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header-destructive">
        <h4 class="modal-title pull-left">{{ 'offtaker.delete.delete-site-groups' | translate }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="deleteModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4>{{ 'offtaker.delete.will-delete' | translate }} {{ selectedIds?.length }} {{ 'offtaker.delete.are-you-sure' | translate }}</h4>
        <div class="text-center">
          <button type="button" class="btn btn-secondary mr-2" aria-label="Close" (click)="deleteModal.hide()">
            {{ 'buttons.cancel' | translate }}
          </button>
          <button type="button" class="btn btn-primary" (click)="emitDeleteProjectSets()">{{ 'buttons.confirm' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #createPortfolioModal="bs-modal"
     [config]="{backdrop:true,ignoreBackdropClick:true,keyboard:true}"
     tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'offtaker.create-portfolio.create-portfolio' | translate }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="createPortfolioModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body create-portfolio-modal-body">
        <form [formGroup]="createPortfolioFormGroup">

          <!-- Portfolio Name -->
          <div class="form-input d-flex flex-column">
            <label for="name" class="required">{{ 'offtaker.create-portfolio.portfolio-name' | translate }}</label>
            <input id="name" formControlName="name" type="text" placeholder="Enter portfolio name" />
          </div>

          <!-- Connect to Program? -->
          <div class="form-input">
            <label class="connect-program-label">{{ 'offtaker.create-portfolio.connect-program' | translate }}?</label>
            <div class="radio-inputs">
              <div class="radio-input">
                <input type="radio" value="true" name="connectProgram" formControlName="connectProgram" />
                <label for="connect-program">{{ 'offtaker.create-portfolio.yes' | translate }}</label>
              </div>
              <div class="radio-input">
                <input type="radio" value="false" name="connectProgram" formControlName="connectProgram" />
                <label for="connect-program">{{ 'offtaker.create-portfolio.no' | translate }}</label>
              </div>
            </div>
          </div>

          <!-- Select Program -->
          <ng-container *ngIf="this.createPortfolioFormGroup.controls['connectProgram'].value === 'true'">
            <div class="select-program">
              <oes-dropdown
                *ngIf="approvedPrograms"
                formControlName="selectOption"
                id="approvedPrograms"
                [parentFormGroup]="createPortfolioFormGroup"
                [items]="approvedPrograms"
                [selection]="'single'"
                [customText]="customText"
                (selectionChanged)="approvedProgramsChanged($event)"
                [enableSearch]="true"
                ngDefaultControl>
              </oes-dropdown>
            </div>
          </ng-container>

        </form>
        <div class="text-right d-flex justify-content-end">
          <button type="button" class="btn btn-secondary mr-2" aria-label="Close" (click)="createPortfolioModal.hide()">
            {{ 'buttons.cancel' | translate }}
          </button>
          <button type="button" class="btn btn-primary" (click)="emitCreatePortfolio()" [disabled]="createPortfolioFormGroup.invalid">{{ 'buttons.create' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
